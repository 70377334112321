<script lang="ts" setup>
import type { BlokArticleShowcase } from '~~/types/storyblok/bloks'
import type { ContentTypeArticleStoryList } from '~~/types/storyblok/stories'

const props = defineProps<{ blok: BlokArticleShowcase }>()

const pageSize = computed(() => {
  return props.blok.articles?.length || (props.blok.quantity as number) || 4
})

const storyQuery = computed(() => {
  return {
    content_type: 'ContentTypeArticle',
    ...(props.blok?.articles?.length
      ? { by_uuids_ordered: [...props.blok.articles].join(',') }
      : { sort_by: 'first_published_at:desc' }),
    starts_with: 'insights/article',
    per_page: pageSize.value,
  }
})

const fetchData = async () => {
  const response = await useAsyncStoryblokStoryQuery(storyQuery.value)
  return response.value.data.stories as ContentTypeArticleStoryList
}

const fetchedArticles = ref(await fetchData())

watch(
  () => props.blok,
  async () => {
    fetchedArticles.value = await fetchData()
  },
  { deep: true }
)
</script>

<template lang="pug">
ArticleShowcase(
  :fetched-articles="fetchedArticles"
  v-editable="blok"
)
</template>
